<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="consultationForm" v-model="valid">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <v-autocomplete
                v-model="department"
                clearable
                outlined
                :items="departments"
                :loading="loadingStatus"
                :filter="departmentFilter"
                label="Select Department"
                item-text="name"
                item-value="name"
                return-object
                @change="getDoctors"
                :rules="[(v) => !!v || 'Department is required']"
              >
                <template v-slot:selection="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                  {{ data.item.name }}
                </template>
              </v-autocomplete>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <v-autocomplete
                v-model="doctor"
                clearable
                outlined
                :items="doctors"
                :loading="loadingStatus"
                :filter="doctorFilter"
                label="Select Doctor"
                item-text="doctor_id.first_name"
                item-value="doctor_id.first_name"
                return-object
                :rules="[(v) => !!v || 'Doctor is required']"
              >
                <template v-slot:selection="data">
                  {{ data.item.doctor_id.first_name }}
                  {{ data.item.doctor_id.last_name }}
                </template>
                <template v-slot:item="data">
                  {{ data.item.doctor_id.first_name }}
                  {{ data.item.doctor_id.last_name }}
                </template>
              </v-autocomplete>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
              <v-textarea
                outlined
                label="Enter Details"
                auto-grow
                v-model="details"
                :rules="detailsRules"
              ></v-textarea>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <v-autocomplete
                v-model="mfid"
                clearable
                outlined
                :items="medical_file"
                :loading="loadingStatus"
                :filter="mfFilter"
                label="Select Medical File"
                item-text="full_name"
                item-value="id"
                return-object
                :rules="[(v) => !!v || 'Medical File is required']"
              >
              </v-autocomplete>
            </div>
          </div>
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          <v-btn
            color="primary mb-3"
            @click="submit"
            :disabled="!valid || submitted"
            >Submit</v-btn
          >
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service';
// import { mapActions } from "vuex";

export default {
  props: ['formTitle', 'record', 'dialog'],
  data() {
    return {
      snackbar: false,
      error: false,
      errorMsg: 'err',
      departments: [],
      department: null,
      doctors: [],
      loadingStatus: true,
      selectedType: '',
      valid: false,
      submitted: false,
      doctor: null,
      details: '',
      medical_file: [],
      mfid: null,
      detailsRules: [
        (v) => !!v || 'Details is required',
        (v) =>
          (v && v.trim().length >= 10) ||
          'Details must be at least 10 characters',
      ],
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.consultationForm.validate()) {
          const consultationForm = {
            recipient: this.doctor.id,
            reason: this.details,
            medical_file: this.mfid,
          };

          this.submitted = true;
          await ApiService.post(
            'items/referral?fields=created_on',
            consultationForm
          );
          this.submitted = false;
          this.$refs.consultationForm.reset();
          this.$emit('formSubmitted', 'consultation sent successfully');
        } else {
          this.snackbar = true;
          this.error = 'please fill all required fields';
        }
      } catch (error) {
        if (error.message !== '') {
          this.error = error;
        } else {
          this.error = 'sorry, something went wrong';
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      // Object.keys(this.departmentForm).map(
      //   (key) => (this.departmentForm[key] = null)
      // );
      this.$emit('closeDialog');
    },
    async getDepartments() {
      try {
        this.loadingStatus = true;
        const { data } = await ApiService.get(
          'items',
          'department?fields=id,name'
        );
        this.departments = data.data;
        console.log(this.departments);
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        this.error = error;
        console.log(error);
      }
    },

    async getMedicalFile() {
      try {
        this.loadingStatus = true;
        const { data } = await ApiService.get(
          'items',
          'medical_file?fields=id,full_name'
        );
        this.medical_file = data.data;
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        this.error = error;
        console.log(error);
      }
    },

    async getDoctors() {
      try {
        if (!this.department) {
          return;
        }
        this.loadingStatus = true;
        const { data } = await ApiService.get(
          'items',
          'doctor?fields=id,doctor_id.id,doctor_id.first_name,doctor_id.last_name,doctor_id.avatar.data,doctor_id.email,department,video,clinic,bio,practice_started&filter[department]=' +
            this.department.id
        );
        console.log('dosctors', data.data);
        this.doctors = data.data;
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        this.error = error;
        console.log(error);
      }
    },

    departmentFilter(department, queryText) {
      console.log({ department, queryText });
      return department.name.toLowerCase().includes(queryText.toLowerCase());
    },

    doctorFilter(doctor, queryText) {
      return (doctor.doctor_id.first_name + ' ' + doctor.doctor_id.last_name)
        .toLowerCase()
        .includes(queryText.toLowerCase());
    },

    mfFilter(mf, queryText) {
      return mf.full_name.toLowerCase().includes(queryText.toLowerCase());
    },
  },

  mounted() {
    this.getDepartments();
    this.getMedicalFile();
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
