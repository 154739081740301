<template>
  <div>
    <h2>Admin Actions</h2>
    <v-btn @click="addConsultation = true" color="primary" class="text-center">
      <v-icon class="mr-2">mdi-plus-box</v-icon>
      Consultation
    </v-btn>
    <MDConsultation
      @closeDialog="addConsultation = false"
      @formSubmitted="formSubmitted"
      formTitle="Add Consultation"
      :dialog="addConsultation"
      v-if="addConsultation"
    />
  </div>
</template>

<script>
import MDConsultation from './comp/MDConsultation';

export default {
  components: {
    MDConsultation,
  },
  data() {
    return {
      addConsultation: false,
    };
  },
  methods: {
    formSubmitted(msg) {
      this.addConsultation = false;
      this.toast('b-toaster-top-center', 'success', msg);
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append,
      });
    },
  },
};
</script>

<style></style>
